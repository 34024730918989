import api from '../api';

import { showAlert, showAlertForError } from './alerts';
import { importFetchedAccounts } from './importer';

export const EVENT_FETCH_REQUEST = 'EVENT_FETCH_REQUEST';
export const EVENT_FETCH_SUCCESS = 'EVENT_FETCH_SUCCESS';
export const EVENT_FETCH_FAIL    = 'EVENT_FETCH_FAIL';

export const EVENTS_FETCH_REQUEST = 'EVENTS_FETCH_REQUEST';
export const EVENTS_FETCH_SUCCESS = 'EVENTS_FETCH_SUCCESS';
export const EVENTS_FETCH_FAIL    = 'EVENTS_FETCH_FAIL';
export const EVENT_UPLOAD_REQUEST    = 'EVENT_UPLOAD_REQUEST';
export const EVENT_UPLOAD_SUCCESS    = 'EVENT_UPLOAD_SUCCESS';
export const EVENT_UPLOAD_FAIL       = 'EVENT_UPLOAD_FAIL';
export const EVENT_UPLOAD_PROGRESS   = 'EVENT_UPLOAD_PROGRESS';

export const EVENT_EDITOR_TITLE_CHANGE = 'EVENT_EDITOR_TITLE_CHANGE';
export const EVENT_EDITOR_SUB_TITLE_CHANGE = 'EVENT_EDITOR_SUB_TITLE_CHANGE';
export const EVENT_EDITOR_DESCRIPTION_CHANGE = 'EVENT_EDITOR_DESCRIPTION_CHANGE';
export const EVENT_EDITOR_SUB_TYPE_CHANGE = 'EVENT_EDITOR_SUB_TYPE_CHANGE';
export const EVENT_EDITOR_EVENT_TYPE_CHANGE = 'EVENT_EDITOR_EVENT_TYPE_CHANGE';
export const EVENT_EDITOR_THEME_VERSE_CHANGE = 'EVENT_EDITOR_THEME_VERSE_CHANGE';
export const EVENT_EDITOR_SART_AT_CHANGE = 'EVENT_EDITOR_SART_AT_CHANGE';
export const EVENT_EDITOR_END_AT_CHANGE = 'EVENT_EDITOR_END_AT_CHANGE';

export const EVENT_EDITOR_VENUE_NAME_CHANGE = 'EVENT_EDITOR_VENUE_NAME_CHANGE'
export const EVENT_EDITOR_ADDRESS_CHANGE = 'EVENT_EDITOR_ADDRESS_CHANGE'
export const EVENT_EDITOR_COUNTRY_CHANGE = 'EVENT_EDITOR_COUNTRY_CHANGE'
export const EVENT_EDITOR_STATE_CHANGE = 'EVENT_EDITOR_STATE_CHANGE'
export const EVENT_EDITOR_CITY_CHANGE = 'EVENT_EDITOR_CITY_CHANGE'
export const EVENT_EDITOR_LINK_CHANGE = 'EVENT_EDITOR_LINK_CHANGE'
export const EVENT_EDITOR_LATITUDE_CHANGE = 'EVENT_EDITOR_LATITUDE_CHANGE'
export const EVENT_EDITOR_LONGITUDE_CHANGE = 'EVENT_EDITOR_LONGITUDE_CHANGE'

export const EVENT_EDITOR_RESET        = 'EVENT_EDITOR_RESET';
export const EVENT_EDITOR_SETUP        = 'EVENT_EDITOR_SETUP';

export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST';
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS';
export const EVENT_CREATE_FAIL    = 'EVENT_CREATE_FAIL';

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_FAIL    = 'EVENT_UPDATE_FAIL';

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_FAIL    = 'EVENT_DELETE_FAIL';

export const EVENT_ACCOUNTS_FETCH_REQUEST = 'EVENT_ACCOUNTS_FETCH_REQUEST';
export const EVENT_ACCOUNTS_FETCH_SUCCESS = 'EVENT_ACCOUNTS_FETCH_SUCCESS';
export const EVENT_ACCOUNTS_FETCH_FAIL    = 'EVENT_ACCOUNTS_FETCH_FAIL';

export const EVENT_EDITOR_SUGGESTIONS_CHANGE = 'EVENT_EDITOR_SUGGESTIONS_CHANGE';
export const EVENT_EDITOR_SUGGESTIONS_READY  = 'EVENT_EDITOR_SUGGESTIONS_READY';
export const EVENT_EDITOR_SUGGESTIONS_CLEAR  = 'EVENT_EDITOR_SUGGESTIONS_CLEAR';

export const EVENT_EDITOR_ADD_REQUEST = 'EVENT_EDITOR_ADD_REQUEST';
export const EVENT_EDITOR_ADD_SUCCESS = 'EVENT_EDITOR_ADD_SUCCESS';
export const EVENT_EDITOR_ADD_FAIL    = 'EVENT_EDITOR_ADD_FAIL';

export const EVENT_EDITOR_REMOVE_REQUEST = 'EVENT_EDITOR_REMOVE_REQUEST';
export const EVENT_EDITOR_REMOVE_SUCCESS = 'EVENT_EDITOR_REMOVE_SUCCESS';
export const EVENT_EDITOR_REMOVE_FAIL    = 'EVENT_EDITOR_REMOVE_FAIL';

export const EVENT_ADDER_RESET = 'EVENT_ADDER_RESET';
export const EVENT_ADDER_SETUP = 'EVENT_ADDER_SETUP';

export const EVENT_ADDER_EVENTS_FETCH_REQUEST = 'EVENT_ADDER_EVENTS_FETCH_REQUEST';
export const EVENT_ADDER_EVENTS_FETCH_SUCCESS = 'EVENT_ADDER_EVENTS_FETCH_SUCCESS';
export const EVENT_ADDER_EVENTS_FETCH_FAIL    = 'EVENT_ADDER_EVENTS_FETCH_FAIL';

export const fetchEvent = id => (dispatch, getState) => {
  // if (getState().getIn(['events', id])) {
  //   return;
  // }

  dispatch(fetchEventRequest(id));

  api().get(`/api/v1/events/${id}`)
    .then(({ data }) => {
      dispatch(fetchEventSuccess(data))})
    .catch(err => dispatch(fetchEventFail(id, err)));
};

export const fetchEventRequest = id => ({
  type: EVENT_FETCH_REQUEST,
  id,
});

export const fetchEventSuccess = event => ({
  type: EVENT_FETCH_SUCCESS,
  event,
});

export const fetchEventFail = (id, error) => ({
  type: EVENT_FETCH_FAIL,
  id,
  error,
});

export const fetchEvents = () => (dispatch) => {
  dispatch(fetchEventsRequest());

  api().get('/api/v1/events')
    .then(({ data }) => dispatch(fetchEventsSuccess(data)))
    .catch(err => dispatch(fetchEventsFail(err)));
};

export const fetchEventsRequest = () => ({
  type: EVENTS_FETCH_REQUEST,
});

export const fetchEventsSuccess = events => ({
  type: EVENTS_FETCH_SUCCESS,
  events,
});

export const fetchEventsFail = error => ({
  type: EVENTS_FETCH_FAIL,
  error,
});

export const submitEventEditor = shouldReset => (dispatch, getState) => {
  const eventId = getState().getIn(['eventEditor', 'eventId']);
  let title = getState().getIn(['eventEditor', 'title']);
  let sub_title = getState().getIn(['eventEditor', 'sub_title']);
  let description = getState().getIn(['eventEditor', 'description']);
  let sub_type = getState().getIn(['eventEditor', 'sub_type']);
  let event_type = getState().getIn(['eventEditor', 'event_type']);
  let theme_verse = getState().getIn(['eventEditor', 'theme_verse']);
  let start_at = getState().getIn(['eventEditor', 'start_at']);
  let end_at = getState().getIn(['eventEditor', 'end_at']);

  let locationId = getState().getIn(['eventEditor', 'locationId']);
  let venue_name = getState().getIn(['eventEditor', 'venue_name']);
  let address = getState().getIn(['eventEditor', 'address']);
  let country = getState().getIn(['eventEditor', 'country']);
  let state = getState().getIn(['eventEditor', 'state']);
  let city = getState().getIn(['eventEditor', 'city']);
  let virtual_link = getState().getIn(['eventEditor', 'virtual_link']);
  let latitude = getState().getIn(['eventEditor', 'latitude']);
  let longitude = getState().getIn(['eventEditor', 'longitude']);
  let media    = getState().getIn(['eventEditor', 'media_attachments']);
  let media_ids = media.map(item => item.get('id'))
  if (eventId === null) {

    let media_attributes;
    media_attributes = media?.map(item => {
      let focus;

      if (item.getIn(['meta', 'focus'])) {
        focus = `${item.getIn(['meta', 'focus', 'x']).toFixed(2)},${item.getIn(['meta', 'focus', 'y']).toFixed(2)}`;
      }

      return {
        id: item.get('id'),
        description: item.get('description'),
        focus,
      };
    });

    dispatch(createEvent(title, sub_title, description, sub_type,
                         event_type, theme_verse, start_at, end_at,
                         venue_name, address, country, state, city,
                         virtual_link, latitude, longitude, 
                         media_attributes, media_ids, shouldReset));
  } else {
    api().get(`/api/v1/events/${eventId}`)
    .then(({ data }) => {
      if(title === '') title = data.title;
      if(sub_title === '') sub_title = data.sub_title;
      if(description === '') description = data.description;
      if(sub_type === '') sub_type = data.sub_type;
      if(event_type === '') event_type = data.event_type;
      if(theme_verse === '') theme_verse = data.theme_verse;
      if(start_at === '') start_at = data.start_at;
      if(end_at === '') end_at = data.end_at;

      if(locationId === '') locationId = data.location.id;
      if(venue_name === '') venue_name = data.location.venue_name;
      if(address === '') address = data.location.address;
      if(country === '') country = data.location.country;
      if(state === '') state = data.location.state;
      if(city === '') city = data.location.city;
      if(virtual_link === '') virtual_link = data.location.virtual_link;
      if(latitude === '') latitude = data.location.latitude;
      if(longitude === '') longitude = data.location.longitude;

      dispatch(updateEvent(eventId, title, sub_title, description, sub_type,
                           event_type, theme_verse, start_at, end_at,
                           locationId, venue_name, address, country, state, city,
                           virtual_link, latitude, longitude, shouldReset));
    })
    .catch(err => console.error(err));
    
  }
};

export const setupEventEditor = eventId => (dispatch, getState) => {
  dispatch({
    type: EVENT_EDITOR_SETUP,
    event: getState().getIn(['events', eventId]),
  });

};

export const changeEventEditorTitle = value => ({
  type: EVENT_EDITOR_TITLE_CHANGE,
  value,
});


export const changeEventEditorSubTitle = value => ({
  type: EVENT_EDITOR_SUB_TITLE_CHANGE,
  value,
});

export const changeEventEditorDescription = value => ({
  type: EVENT_EDITOR_DESCRIPTION_CHANGE,
  value,
});

export const changeEventEditorSubType = value => ({
  type: EVENT_EDITOR_SUB_TYPE_CHANGE,
  value,
});

export const changeEventEditorEventType = value => ({
  type: EVENT_EDITOR_EVENT_TYPE_CHANGE,
  value,
});

export const changeEventEditorThemeVerse = value => ({
  type: EVENT_EDITOR_THEME_VERSE_CHANGE,
  value,
});

export const changeEventEditorStartAt = value => ({
  type: EVENT_EDITOR_SART_AT_CHANGE,
  value,
});

export const changeEventEditorEndAt = value => ({
  type: EVENT_EDITOR_END_AT_CHANGE,
  value,
});

export const changeEventEditorVenueName = value => ({
  type: EVENT_EDITOR_VENUE_NAME_CHANGE,
  value,
});

export const changeEventEditorAddress = value => ({
  type: EVENT_EDITOR_ADDRESS_CHANGE,
  value,
});

export const changeEventEditorCountry = value => ({
  type: EVENT_EDITOR_COUNTRY_CHANGE,
  value,
});

export const changeEventEditorState = value => ({
  type: EVENT_EDITOR_STATE_CHANGE,
  value,
});

export const changeEventEditorCity = value => ({
  type: EVENT_EDITOR_CITY_CHANGE,
  value,
});

export const changeEventEditorLink = value => ({
  type: EVENT_EDITOR_LINK_CHANGE,
  value,
});

export const changeEventEditorLatitude = value => ({
  type: EVENT_EDITOR_LATITUDE_CHANGE,
  value,
});

export const changeEventEditorLongitude = value => ({
  type: EVENT_EDITOR_LONGITUDE_CHANGE,
  value,
});

export const createEvent = (title, sub_title, description, sub_type,
                            event_type, theme_verse, start_at, end_at,
                            venue_name, address, country, state, city,
                            virtual_link, latitude, longitude,
                            media_attributes, media_ids,
                            shouldReset) => (dispatch) => {
  dispatch(createEventRequest());
  

  api().post('/api/v1/events', { title, sub_title, description, sub_type,
                                 event_type, theme_verse, start_at, end_at,
                                 media_attributes, media_ids,
                                 location_attributes: {
                                  venue_name, address, country, state, city,
                                   virtual_link, latitude, longitude
                                  }
                                }).then(({ data }) => {
    dispatch(createEventSuccess(data));

    if (shouldReset) {
      dispatch(resetEventEditor());
    }
  }).catch(err => dispatch(createEventFail(err)));
};

export const createEventRequest = () => ({
  type: EVENT_CREATE_REQUEST,
});

export const createEventSuccess = event => ({
  type: EVENT_CREATE_SUCCESS,
  event,
});

export const createEventFail = error => ({
  type: EVENT_CREATE_FAIL,
  error,
});

export const updateEvent = (id, title, sub_title, description, sub_type,
                            event_type, theme_verse, start_at, end_at,
                            locationId, venue_name, address, country, state, city,
                            virtual_link, latitude, longitude,
                            shouldReset, ) => (dispatch) => {
  dispatch(updateEventRequest(id));

  api().put(`/api/v1/events/${id}`, { title, sub_title, description, sub_type,
                                      event_type, theme_verse, start_at, end_at,
                                      location_attributes:{
                                        id: locationId, venue_name, address, country, state,
                                        city, virtual_link, latitude, longitude
                                    }
                                  }).then(({ data }) => {
    dispatch(updateEventSuccess(data));

    if (shouldReset) {
      dispatch(resetEventEditor());
    }
  }).catch(err => dispatch(updateEventFail(id, err)));
};

export const updateEventRequest = id => ({
  type: EVENT_UPDATE_REQUEST,
  id,
});

export const updateEventSuccess = event => ({
  type: EVENT_UPDATE_SUCCESS,
  event,
});

export const updateEventFail = (id, error) => ({
  type: EVENT_UPDATE_FAIL,
  id,
  error,
});

export const resetEventEditor = () => ({
  type: EVENT_EDITOR_RESET,
});

export const deleteEvent = id => (dispatch) => {
  dispatch(deleteEventRequest(id));

  api().delete(`/api/v1/events/${id}`)
    .then(() => dispatch(deleteEventSuccess(id)))
    .catch(err => dispatch(deleteEventFail(id, err)));
};

export const deleteEventRequest = id => ({
  type: EVENT_DELETE_REQUEST,
  id,
});

export const deleteEventSuccess = id => ({
  type: EVENT_DELETE_SUCCESS,
  id,
});

export const deleteEventFail = (id, error) => ({
  type: EVENT_DELETE_FAIL,
  id,
  error,
});

export const fetchEventAccounts = eventId => (dispatch) => {
  dispatch(fetchEventAccountsRequest(eventId));

  api().get(`/api/v1/events/${eventId}/accounts`, { params: { limit: 0 } }).then(({ data }) => {
    dispatch(importFetchedAccounts(data));
    dispatch(fetchEventAccountsSuccess(eventId, data));
  }).catch(err => dispatch(fetchEventAccountsFail(eventId, err)));
};

export const fetchEventAccountsRequest = id => ({
  type: EVENT_ACCOUNTS_FETCH_REQUEST,
  id,
});

export const fetchEventAccountsSuccess = (id, accounts, next) => ({
  type: EVENT_ACCOUNTS_FETCH_SUCCESS,
  id,
  accounts,
  next,
});

export const fetchEventAccountsFail = (id, error) => ({
  type: EVENT_ACCOUNTS_FETCH_FAIL,
  id,
  error,
});

export const fetchEventSuggestions = q => (dispatch) => {
  const params = {
    q,
    resolve: false,
    limit: 4,
    following: true,
  };

  api().get('/api/v1/accounts/search', { params }).then(({ data }) => {
    dispatch(importFetchedAccounts(data));
    dispatch(fetchEventSuggestionsReady(q, data));
  }).catch(error => dispatch(showAlertForError(error)));
};

export const fetchEventSuggestionsReady = (query, accounts) => ({
  type: EVENT_EDITOR_SUGGESTIONS_READY,
  query,
  accounts,
});

export const clearEventSuggestions = () => ({
  type: EVENT_EDITOR_SUGGESTIONS_CLEAR,
});

export const changeEventSuggestions = value => ({
  type: EVENT_EDITOR_SUGGESTIONS_CHANGE,
  value,
});

export const addToEventEditor = () => (dispatch, getState) => {
  dispatch(addToEvent(getState().getIn(['eventEditor', 'eventId'])));
};

export const addToEvent = (eventId, accountId) => (dispatch) => {
  dispatch(addToEventRequest(eventId));

  api().post(`/api/v1/events/${eventId}/accounts`, { account_ids: [accountId] })
    .then(() => dispatch(addToEventSuccess(eventId)))
    .catch(err => dispatch(addToEventFail(eventId, err)));
};

export const addToEventRequest = (eventId) => ({
  type: EVENT_EDITOR_ADD_REQUEST,
  eventId,
});

export const addToEventSuccess = (eventId) => ({
  type: EVENT_EDITOR_ADD_SUCCESS,
  eventId,
});

export const addToEventFail = (eventId, error) => ({
  type: EVENT_EDITOR_ADD_FAIL,
  eventId,
  error,
});

export const removeFromEventEditor = accountId => (dispatch, getState) => {
  dispatch(removeFromEvent(getState().getIn(['eventEditor', 'eventId']), accountId));
};

export const removeFromEvent = (eventId, accountId) => (dispatch) => {
  dispatch(removeFromEventRequest(eventId, accountId));

  api().delete(`/api/v1/events/${eventId}/accounts`, { params: { account_ids: [accountId] } })
    .then(() => dispatch(removeFromEventSuccess(eventId, accountId)))
    .catch(err => dispatch(removeFromEventFail(eventId, accountId, err)));
};

export const removeFromEventRequest = (eventId, accountId) => ({
  type: EVENT_EDITOR_REMOVE_REQUEST,
  eventId,
  accountId,
});

export const removeFromEventSuccess = (eventId, accountId) => ({
  type: EVENT_EDITOR_REMOVE_SUCCESS,
  eventId,
  accountId,
});

export const removeFromEventFail = (eventId, accountId, error) => ({
  type: EVENT_EDITOR_REMOVE_FAIL,
  eventId,
  accountId,
  error,
});

export const resetEventAdder = () => ({
  type: EVENT_ADDER_RESET,
});

export const setupEventAdder = accountId => (dispatch, getState) => {
  dispatch({
    type: EVENT_ADDER_SETUP,
    account: getState().getIn(['accounts', accountId]),
  });
  dispatch(fetchEvents());
  dispatch(fetchAccountEvents(accountId));
};

export const fetchAccountEvents = accountId => (dispatch) => {
  dispatch(fetchAccountEventsRequest(accountId));

  api().get(`/api/v1/accounts/${accountId}/events`)
    .then(({ data }) => dispatch(fetchAccountEventsSuccess(accountId, data)))
    .catch(err => dispatch(fetchAccountEventsFail(accountId, err)));
};

export const fetchAccountEventsRequest = id => ({
  type:EVENT_ADDER_EVENTS_FETCH_REQUEST,
  id,
});

export const fetchAccountEventsSuccess = (id, events) => ({
  type: EVENT_ADDER_EVENTS_FETCH_SUCCESS,
  id,
  events,
});

export const fetchAccountEventsFail = (id, err) => ({
  type: EVENT_ADDER_EVENTS_FETCH_FAIL,
  id,
  err,
});

export const addToEventAdder = eventId => (dispatch, getState) => {
  dispatch(addToEvent(eventId, getState().getIn(['eventAdder', 'accountId'])));
};

export const removeFromEventAdder = eventId => (dispatch, getState) => {
  dispatch(removeFromEvent(eventId, getState().getIn(['eventAdder', 'accountId'])));
};


export function uploadEvent(files) {
  return function (dispatch, getState) {
    const uploadLimit = getState().getIn(['server', 'server', 'configuration', 'statuses', 'max_media_attachments']);
    const media = getState().getIn(['eventEditor', 'media_attachments']);
    const pending = getState().getIn(['eventEditor', 'pending_media_attachments']);
    const progress = new Array(files.length).fill(0);

    let total = Array.from(files).reduce((a, v) => a + v.size, 0);
    if (files.length + media.size + pending > uploadLimit) {
      dispatch(showAlert({ message: messages.uploadErrorLimit }));
      return;
    }

    if (getState().getIn(['events', 'poll'])) {
      dispatch(showAlert({ message: messages.uploadErrorPoll }));
      return;
    }

    dispatch(uploadEventRequest());

    for (const [i, file] of Array.from(files).entries()) {
      if (media.size + i > (uploadLimit - 1)) break;

      const data = new FormData();
      data.append('file', file);

      api().post('/api/v2/media', data, {
        onUploadProgress: function({ loaded }){
          progress[i] = loaded;
          dispatch(uploadEventProgress(progress.reduce((a, v) => a + v, 0), total));
        },
      }).then(({ status, data }) => {
        // If server-side processing of the media attachment has not completed yet,
        // poll the server until it is, before showing the media attachment as uploaded

        if (status === 200) {
          dispatch(uploadEventSuccess(data, file));
        } else if (status === 202) {
          dispatch(uploadEventProcessing());

          let tryCount = 1;

          const poll = () => {
            api().get(`/api/v1/media/${data.id}`).then(response => {
              if (response.status === 200) {
                dispatch(uploadEventSuccess(response.data, file));
              } else if (response.status === 206) {
                const retryAfter = (Math.log2(tryCount) || 1) * 1000;
                tryCount += 1;
                setTimeout(() => poll(), retryAfter);
              }
            }).catch(error => dispatch(uploadEventFail(error)));
          };

          poll();
        }
      }).catch(error => dispatch(uploadEventFail(error)));
    }
  };
}

export function uploadEventRequest() {
  return {
    type: EVENT_UPLOAD_REQUEST,
    skipLoading: true,
  };
}
export function uploadEventProgress(loaded, total) {
  return {
    type: EVENT_UPLOAD_PROGRESS,
    loaded: loaded,
    total: total,
  };
}
export function uploadEventSuccess(media, file) {
  return {
    type: EVENT_UPLOAD_SUCCESS,
    media: media,
    file: file,
    skipLoading: true,
  };
}
export function uploadEventFail(error) {
  return {
    type: EVENT_UPLOAD_FAIL,
    error: error,
    skipLoading: true,
  };
}