import { Map as ImmutableMap, fromJS } from 'immutable';

import {
  EVENT_FETCH_SUCCESS,
  EVENT_FETCH_FAIL,
  EVENTS_FETCH_SUCCESS,
  EVENT_CREATE_SUCCESS,
  EVENT_UPDATE_SUCCESS,
  EVENT_DELETE_SUCCESS,
} from '../actions/events';

const initialState = ImmutableMap();

const normalizeEvent = (state, event) => state.set(event.id, fromJS(event));

const normalizeEvents = (state, events) => {

  events.forEach(event => {
    state = normalizeEvent(state, event);
  });

  return state;
};

export default function events(state = initialState, action) {
  switch(action.type) {
  case EVENT_FETCH_SUCCESS:
  case EVENT_CREATE_SUCCESS:
  case EVENT_UPDATE_SUCCESS:
    return normalizeEvent(state, action.event);
  case EVENTS_FETCH_SUCCESS:
    return normalizeEvents(state, action.events);
  case EVENT_DELETE_SUCCESS:
  case EVENT_FETCH_FAIL:
    return state.set(action.id, false);
  default:
    return state;
  }
}
