import { Map as ImmutableMap, List as ImmutableList } from 'immutable';

import {
  EVENT_ADDER_RESET,
  EVENT_ADDER_SETUP,
  EVENT_ADDER_EVENTS_FETCH_REQUEST,
  EVENT_ADDER_EVENTS_FETCH_SUCCESS,
  EVENT_ADDER_EVENTS_FETCH_FAIL,
  EVENT_EDITOR_ADD_SUCCESS,
  EVENT_EDITOR_REMOVE_SUCCESS,
} from '../actions/events';

const initialState = ImmutableMap({
  accountId: null,

  events: ImmutableMap({
    items: ImmutableList(),
    loaded: false,
    isLoading: false
  }),
});

export default function eventAdderReducer(state = initialState, action) {
  switch(action.type) {
  case EVENT_ADDER_RESET:
    return initialState;
  case EVENT_ADDER_SETUP:
    return state.withMutations(map => {
      map.set('accountId', action.account.get('id'));
    });
  case EVENT_ADDER_EVENTS_FETCH_REQUEST:
    return state.setIn(['events', 'isLoading'], true);
  case EVENT_ADDER_EVENTS_FETCH_FAIL:
    return state.setIn(['events', 'isLoading'], false);
  case EVENT_ADDER_EVENTS_FETCH_SUCCESS:
    return state.update('events', events => events.withMutations(map => {
      map.set('isLoading', false);
      map.set('loaded', true);
      map.set('items', ImmutableList(action.events.map(item => item.id)));
    }));
  case EVENT_EDITOR_ADD_SUCCESS:
    return state.updateIn(['events', 'items'], event => event.unshift(action.eventId));
  case EVENT_EDITOR_REMOVE_SUCCESS:
    return state.updateIn(['events', 'items'], event => event.filterNot(item => item === action.eventId));
  default:
    return state;
  }
}
