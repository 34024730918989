import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { uploadEvent } from '../../../actions/events';

import { defineMessages, injectIntl } from 'react-intl';
// import UploadButtonContainer from '../../compose/containers/upload_button_container';

import { connect } from 'react-redux';
import { Country, State, City } from "country-state-city";

import { 
  changeEventEditorTitle,
  changeEventEditorSubTitle,
  submitEventEditor,
  changeEventEditorDescription,
  changeEventEditorSubType,
  changeEventEditorEventType,
  changeEventEditorThemeVerse,
  changeEventEditorStartAt,
  changeEventEditorEndAt,
  changeEventEditorVenueName,
  changeEventEditorAddress,
  changeEventEditorCountry,
  changeEventEditorState,
  changeEventEditorCity,
  changeEventEditorLink,
  changeEventEditorLatitude,
  changeEventEditorLongitude
} from 'mastodon/actions/events';
import { Button } from 'mastodon/components/button';

const messages = defineMessages({
  label: { id: 'events.new.title_placeholder', defaultMessage: 'New event title' },
  subTitleLabel: { id: 'events.new.sub_title_placeholder', defaultMessage: 'New event sub title' },
  descriptionLabel: { id: 'events.new.description_placeholder', defaultMessage: 'New event description' },
  subTypeLabel: { id: 'events.new.sub_type_placeholder', defaultMessage: 'New event sub type' },
  eventTypeLabel: { id: 'events.new.event_type_placeholder', defaultMessage: 'New event type' },
  themeVerseLabel: { id: 'events.new.theme_verse_placeholder', defaultMessage: 'theme verse' },
  startAtLabel: { id: 'events.new.start_at_placeholder', defaultMessage: 'event start time' },
  endAtLabel: { id: 'events.new.end_at_placeholder', defaultMessage: 'event end time' },
  title: { id: 'events.new.create', defaultMessage: 'Add Event' },
  upload: { id: 'events.new.upload_button', defaultMessage: 'Add images or a video file' },

  venueNameLabel: { id: 'events.new.venue_name_placeholder', defaultMessage: 'venue name' },
  addressLabel: { id: 'events.new.address_placeholder', defaultMessage: 'event address' },
  countryLabel: { id: 'events.new.country_placeholder', defaultMessage: 'event country' },
  stateLabel: { id: 'events.new.state_placeholder', defaultMessage: 'event state' },
  cityLabel: { id: 'events.new.city_placeholder', defaultMessage: 'event city' },
  linkLabel: { id: 'events.new.virtual_link_placeholder', defaultMessage: 'event link' },
  latitudeLabel: { id: 'events.new.latitude_placeholder', defaultMessage: 'latitude' },
  longitudeLabel: { id: 'events.new.longitude_placeholder', defaultMessage: 'longitude' },
});

const mapStateToProps = state => ({
  value: state.getIn(['eventEditor', 'title']),
  subTitle: state.getIn(['eventEditor', 'sub_title']),
  description: state.getIn(['eventEditor', 'description']),
  subType: state.getIn(['eventEditor', 'sub_type']),
  eventType: state.getIn(['eventEditor', 'event_type']),
  themeVerse: state.getIn(['eventEditor', 'theme_verse']),
  startAt: state.getIn(['eventEditor', 'start_at']),
  endAt: state.getIn(['eventEditor', 'end_at']),
  disabled: state.getIn(['eventEditor', 'isSubmitting']),
  acceptContentTypes: state.getIn(['media_attachments', 'accept_content_types']),

  venueName: state.getIn(['eventEditor', 'venue_name']),
  address: state.getIn(['eventEditor', 'address']),
  country: state.getIn(['eventEditor', 'country']),
  state: state.getIn(['eventEditor', 'state']),
  city: state.getIn(['eventEditor', 'city']),
  link: state.getIn(['eventEditor', 'virtual_link']),
  latitude: state.getIn(['eventEditor', 'latitude']),
  longitude: state.getIn(['eventEditor', 'longitude']),
});

// const makeMapStateToProps = () => {
//   const mapStateToProps = state => ({
    
//   });

//   return mapStateToProps;
// };

const mapDispatchToProps = dispatch => ({
  onChangeTitle: value => dispatch(changeEventEditorTitle(value)),
  onChangeSubTitle: subTitle => dispatch(changeEventEditorSubTitle(subTitle)),
  onChangeDescription: description => dispatch(changeEventEditorDescription(description)),
  onChangeSubType: subType => dispatch(changeEventEditorSubType(subType)),
  onChangeEventType: eventType => dispatch(changeEventEditorEventType(eventType)),
  onChangeThemeVerse: themeVerse => dispatch(changeEventEditorThemeVerse(themeVerse)),
  onChangeStartAt: startAt => dispatch(changeEventEditorStartAt(startAt)),
  onChangeEndAt: endAt => dispatch(changeEventEditorEndAt(endAt)),
  onSelectFile: files => dispatch(uploadEvent(files)),

  onChangeVenueName: venueName => dispatch(changeEventEditorVenueName(venueName)),
  onChangeAddress: address => dispatch(changeEventEditorAddress(address)),
  onChangeCountry: country => dispatch(changeEventEditorCountry(country)),
  onChangeState: state => dispatch(changeEventEditorState(state)),
  onChangeCity: city => dispatch(changeEventEditorCity(city)),
  onChangeLink: link => dispatch(changeEventEditorLink(link)),
  onChangeLatitude: latitude => dispatch(changeEventEditorLatitude(latitude)),
  onChangeLongitude: longitude => dispatch(changeEventEditorLongitude(longitude)),
  onSubmit: () => dispatch(submitEventEditor(true)),
});

class NewEventForm extends PureComponent {

  static propTypes = {
    value: PropTypes.string.isRequired,
    acceptContentTypes: ImmutablePropTypes.listOf(PropTypes.string),
    resetFileKey: PropTypes.number,
    subTitle: PropTypes.string,
    description: PropTypes.string.isRequired,
    subType: PropTypes.string,
    eventType: PropTypes.string.isRequired,
    themeVerse: PropTypes.string,
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,

    venueName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    link: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    disabled: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    onChangeTitle: PropTypes.func.isRequired,
    onChangeSubTitle: PropTypes.func.isRequired,
    onChangeDescription: PropTypes.func.isRequired,
    onChangeSubType: PropTypes.func.isRequired,
    onChangeEventType: PropTypes.func.isRequired,
    onChangeThemeVerse: PropTypes.func.isRequired,
    onChangeStartAt: PropTypes.func.isRequired,
    onChangeEndAt: PropTypes.func.isRequired,
    onSelectFile: PropTypes.func.isRequired,

    onChangeVenueName: PropTypes.func.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
    onChangeCountry: PropTypes.func.isRequired,
    onChangeState: PropTypes.func.isRequired,
    onChangeCity: PropTypes.func.isRequired,
    onChangeLink: PropTypes.func.isRequired,
    onChangeLatitude: PropTypes.func.isRequired,
    onChangeLongitude: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      countryIsoCode: '',
      stateIsoCode: ''
    };
  }

  handleTitle = e => {
    this.props.onChangeTitle(e.target.value);
  };

  handleSubTitle = e => {
    this.props.onChangeSubTitle(e.target.value);
  };

  handleDescription = e => {
    this.props.onChangeDescription(e.target.value);
  };

  handleSubType = e => {
    this.props.onChangeSubType(e.target.value);
  };

  handleEventType = e => {
    this.props.onChangeEventType(e.target.value);
  };

  handleThemeVerse = e => {
    this.props.onChangeThemeVerse(e.target.value);
  };

  handleStartAt = e => {
    this.props.onChangeStartAt(e.target.value);
  };

  handleEndAt = e => {
    this.props.onChangeEndAt(e.target.value);
  };
  // location handlers
  handleVenueName = e => {
    this.props.onChangeVenueName(e.target.value);
  };
  handleAddress = e => {
    this.props.onChangeAddress(e.target.value);
  };
  handleCountry = e => {
    const country = Country.getAllCountries().find(country => country.name === e.target.value);
    const countryIsoCode = country.isoCode;
    this.setState({ countryIsoCode });
    this.props.onChangeCountry(e.target.value);
  };
  handleState = e => {
    const state = State.getStatesOfCountry(this.state.countryIsoCode).find(state => state.name === e.target.value);
    const stateIsoCode = state.isoCode;
    this.setState({ stateIsoCode });
    this.props.onChangeState(e.target.value);
  };
  handleCity = e => {
    this.props.onChangeCity(e.target.value);
  };
  handleLink = e => {
    this.props.onChangeLink(e.target.value);
  };
  handleLatitude = e => {
    this.props.onChangeLatitude(e.target.value);
  };
  handleLongitude = e => {
    this.props.onChangeLongitude(e.target.value);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit();
  };

  handleClick = () => {
    this.props.onSubmit();
  };

  handleFile = () => {
    this.fileElement.click();
  };

  setRef = (c) => {
    this.fileElement = c;
  };

  handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      this.props.onSelectFile(e.target.files);
    }
  };

  render () {

    const { countryIsoCode, stateIsoCode } = this.state;
    const { value, subTitle, description, subType,
            eventType, themeVerse, startAt, endAt, 
            venueName, address, country, state,
            city, link, latitude, longitude, 
            disabled, intl, resetFileKey, acceptContentTypes} = this.props;
    const label = intl.formatMessage(messages.label);
    const subTitleLabel = intl.formatMessage(messages.subTitleLabel);
    const descriptionLabel = intl.formatMessage(messages.descriptionLabel);
    const subTypeLabel = intl.formatMessage(messages.subTypeLabel);
    const eventTypeLabel = intl.formatMessage(messages.eventTypeLabel);
    const themeVerseLabel = intl.formatMessage(messages.themeVerseLabel);
    const startAtLabel = intl.formatMessage(messages.startAtLabel);
    const endAtLabel = intl.formatMessage(messages.endAtLabel);

    const venueNameLabel = intl.formatMessage(messages.venueNameLabel);
    const addressLabel = intl.formatMessage(messages.addressLabel);
    const countryLabel = intl.formatMessage(messages.countryLabel);
    const stateLabel = intl.formatMessage(messages.stateLabel);
    const cityLabel = intl.formatMessage(messages.cityLabel);
    const linkLabel = intl.formatMessage(messages.linkLabel);
    const latitudeLabel = intl.formatMessage(messages.latitudeLabel);
    const longitudeLabel = intl.formatMessage(messages.longitudeLabel);
    const title = intl.formatMessage(messages.title);
    const uploadLabel = intl.formatMessage(messages.upload);

    return (
      <form className='column-inline-event-form' onSubmit={this.handleSubmit}>
        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{label}</span>
              <input
                className='setting-text event-text'
                value={value}
                disabled={disabled}
                onChange={this.handleTitle}
                placeholder={label}
              />
            </label>
          </div>
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{subTitleLabel}</span>
              <input
                className='setting-text event-text'
                value={subTitle}
                disabled={disabled}
                onChange={this.handleSubTitle}
                placeholder={subTitleLabel}
              />
            </label>
          </div>
        </div>

        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{descriptionLabel}</span>
              <input
                className='setting-text event-text'
                value={description}
                disabled={disabled}
                onChange={this.handleDescription}
                placeholder={descriptionLabel}
              />
            </label>
          </div>

          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{subTypeLabel}</span>
              <input
                className='setting-text event-text'
                value={subType}
                disabled={disabled}
                onChange={this.handleSubType}
                placeholder={subTypeLabel}
              />
            </label>
          </div>
        </div>

        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{eventTypeLabel}</span>
              <select
                className='setting-text event-text'
                disabled={disabled}
                placeholder={eventTypeLabel}
                value={eventType}
                onChange={this.handleEventType}>
                <option value="">--Please choose an option--</option>
                <option value="crusade">crusade</option>
                <option value="conference">conference</option>
                <option value="seminar">seminar</option>
                <option value="workshop">workshop</option>
                <option value="retreat">retreat</option>
              </select>
            </label>
          </div>

          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{themeVerseLabel}</span>
              <input
                className='setting-text event-text'
                value={themeVerse}
                disabled={disabled}
                onChange={this.handleThemeVerse}
                placeholder={themeVerseLabel}
              />
            </label>
          </div>
        </div>

        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{startAtLabel}</span>
              <input
                type="datetime-local"
                className='setting-text event-text'
                value={startAt}
                disabled={disabled}
                onChange={this.handleStartAt}
                placeholder={startAtLabel}
              />
            </label>
          </div>

          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{endAtLabel}</span>
              <input
                type="datetime-local"
                className='setting-text event-text'
                value={endAt}
                disabled={disabled}
                onChange={this.handleEndAt}
                placeholder={endAtLabel}
              />
            </label>
          </div>
        </div>

        {/*  location inputs */}

        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{venueNameLabel}</span>
              <input
                className='setting-text event-text'
                value={venueName}
                disabled={disabled}
                onChange={this.handleVenueName}
                placeholder={venueNameLabel}
              />
            </label>
          </div>

          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{addressLabel}</span>
              <input
                className='setting-text event-text'
                value={address}
                disabled={disabled}
                onChange={this.handleAddress}
                placeholder={addressLabel}
              />
            </label>
          </div>
        </div>

        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{countryLabel}</span>
              <select 
                className='setting-text event-text'
                disabled={disabled}
                placeholder={countryLabel}
                value={country} 
                onChange={this.handleCountry}>
                  <option value="">--Select Country--</option>
                    {Country.getAllCountries().map((country) => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
              </select>
            </label>
          </div>

          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{stateLabel}</span>
              <select 
                className='setting-text event-text'
                disabled={disabled}
                placeholder={stateLabel}
                value={state} 
                onChange={this.handleState}>
                  <option value="">--Select State--</option>
                    {State.getStatesOfCountry(countryIsoCode).map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
              </select>
            </label>
          </div>
        </div>

        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{cityLabel}</span>
              <select 
                className='setting-text event-text'
                disabled={disabled}
                placeholder={cityLabel}
                value={city} 
                onChange={this.handleCity}>
                  <option value="">--Select City--</option>
                    {City.getCitiesOfState( countryIsoCode, stateIsoCode ).map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
              </select>
            </label>
          </div>

          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{linkLabel}</span>
              <input
                className='setting-text event-text'
                value={link}
                disabled={disabled}
                onChange={this.handleLink}
                placeholder={linkLabel}
              />
            </label>
          </div>
        </div>

        <div className="event-input-row">
          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{latitudeLabel}</span>
              <input
                className='setting-text event-text'
                value={latitude}
                disabled={disabled}
                onChange={this.handleLatitude}
                placeholder={latitudeLabel}
              />
            </label>
          </div>

          <div className="event-input">
            <label>
              <span style={{ display: 'none' }}>{longitudeLabel}</span>
              <input
                className='setting-text event-text'
                value={longitude}
                disabled={disabled}
                onChange={this.handleLongitude}
                placeholder={longitudeLabel}
              />
            </label>
          </div>
        </div>

        <div className="event-input-row">
          <div className="event-input">
            <div className='compose-form__upload-button'>
              <label>
                <span style={{ display: 'none' }} onClick={this.handleClick}>{uploadLabel}</span>
                <input
                 className='setting-text event-text'
                 placeholder={uploadLabel}
                  key={resetFileKey}
                  ref={this.setRef}
                  type='file'
                  name='file-upload-input'
                  multiple
                  accept={acceptContentTypes?.toArray().join(',')}
                  onChange={this.handleFileChange}
                  disabled={disabled}
                />
              </label>
            </div>

            {/* <UploadButtonContainer /> */}
          </div>
        </div>

        <Button
          className="event-button"
          disabled={disabled || !value}
          text={title}
          onClick={this.handleClick}
        />
      </form>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewEventForm));
