import { Map as ImmutableMap, List as ImmutableList, fromJS } from 'immutable';
import { uuid } from '../uuid';
import {
  EVENT_CREATE_REQUEST,
  EVENT_CREATE_FAIL,
  EVENT_CREATE_SUCCESS,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_FAIL,
  EVENT_UPDATE_SUCCESS,
  EVENT_EDITOR_RESET,
  EVENT_EDITOR_SETUP,
  EVENT_EDITOR_TITLE_CHANGE,
  EVENT_EDITOR_SUB_TITLE_CHANGE,
  EVENT_EDITOR_DESCRIPTION_CHANGE,
  EVENT_EDITOR_SUB_TYPE_CHANGE,
  EVENT_EDITOR_EVENT_TYPE_CHANGE,
  EVENT_EDITOR_THEME_VERSE_CHANGE,
  EVENT_EDITOR_SART_AT_CHANGE,
  EVENT_EDITOR_END_AT_CHANGE,
  EVENT_EDITOR_VENUE_NAME_CHANGE,
  EVENT_EDITOR_ADDRESS_CHANGE,
  EVENT_EDITOR_COUNTRY_CHANGE,
  EVENT_EDITOR_STATE_CHANGE,
  EVENT_EDITOR_CITY_CHANGE,
  EVENT_EDITOR_LINK_CHANGE,
  EVENT_EDITOR_LATITUDE_CHANGE,
  EVENT_EDITOR_LONGITUDE_CHANGE,
  EVENT_UPLOAD_REQUEST,
  EVENT_UPLOAD_SUCCESS,
  EVENT_UPLOAD_FAIL,
  EVENT_UPLOAD_PROGRESS,
  EVENT_ACCOUNTS_FETCH_REQUEST,
  EVENT_ACCOUNTS_FETCH_SUCCESS,
  EVENT_ACCOUNTS_FETCH_FAIL,
  EVENT_EDITOR_SUGGESTIONS_READY,
  EVENT_EDITOR_SUGGESTIONS_CLEAR,
  EVENT_EDITOR_SUGGESTIONS_CHANGE,
  EVENT_EDITOR_ADD_SUCCESS,
  EVENT_EDITOR_REMOVE_SUCCESS,
} from '../actions/events';

const initialState = ImmutableMap({
  eventId: null,
  isSubmitting: false,
  isChanged: false,
  title: '',
  sub_title: '',
  description: '',
  sub_type: '',
  event_type: '',
  theme_verse: '',
  start_at: '',
  end_at: '',

  locationId: '',
  venue_name: '',
  address: '',
  country: '',
  state: '',
  city: '',
  virtual_link: '',
  latitude: '',
  longitude: '',
  is_uploading: false,
  progress: 0,
  isExclusive: false,
  media_attachments: ImmutableList(),
  pending_media_attachments: 0,
  accounts: ImmutableMap({
    items: ImmutableList(),
    loaded: false,
    isLoading: false,
  }),
  suggestions: ImmutableMap({
    value: '',
    items: ImmutableList(),
  }),
  media_modal: ImmutableMap({
    id: null,
    description: '',
    focusX: 0,
    focusY: 0,
    dirty: false,
  }),
});

function appendMedia(state, media, file) {
  const prevSize = state.get('media_attachments').size;

  return state.withMutations(map => {
    if (media.get('type') === 'image') {
      media = media.set('file', file);
    }
    map.update('media_attachments', list => list.push(media.set('unattached', true)));
    map.set('is_uploading', false);
    map.set('is_processing', false);
    map.set('resetFileKey', Math.floor((Math.random() * 0x10000)));
    map.set('idempotencyKey', uuid());
    map.update('pending_media_attachments', n => n - 1);

    if (prevSize === 0 && (state.get('default_sensitive') || state.get('spoiler'))) {
      map.set('sensitive', true);
    }
  });
}

export default function eventEditorReducer(state = initialState, action) {
  switch(action.type) {
  case EVENT_EDITOR_RESET:
    return initialState;
  case EVENT_EDITOR_SETUP:
    return state.withMutations(map => {
      map.set('eventId', action.event.get('id'));
      map.set('title', action.event.get('title'));
      map.set('isExclusive', action.event.get('is_exclusive'));
      map.set('isSubmitting', false);
    });
  case EVENT_EDITOR_TITLE_CHANGE:
    return state.withMutations(map => {
      map.set('title', action.value);
      map.set('isChanged', true);
    });
  case EVENT_EDITOR_SUB_TITLE_CHANGE:
    return state.withMutations(map => {
      map.set('sub_title', action.value);
      map.set('isChanged', true);
    });
  case EVENT_EDITOR_DESCRIPTION_CHANGE:
    return state.withMutations(map => {
      map.set('description', action.value);
      map.set('isChanged', true);
    });
  case EVENT_EDITOR_SUB_TYPE_CHANGE:
  return state.withMutations(map => {
    map.set('sub_type', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_EVENT_TYPE_CHANGE:
  return state.withMutations(map => {
    map.set('event_type', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_THEME_VERSE_CHANGE:
  return state.withMutations(map => {
    map.set('theme_verse', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_SART_AT_CHANGE:
  return state.withMutations(map => {
    map.set('start_at', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_END_AT_CHANGE:
  return state.withMutations(map => {
    map.set('end_at', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_VENUE_NAME_CHANGE:
  return state.withMutations(map => {
    map.set('venue_name', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_ADDRESS_CHANGE:
  return state.withMutations(map => {
    map.set('address', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_COUNTRY_CHANGE:
  return state.withMutations(map => {
    map.set('country', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_STATE_CHANGE:
  return state.withMutations(map => {
    map.set('state', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_CITY_CHANGE:
  return state.withMutations(map => {
    map.set('city', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_LINK_CHANGE:
  return state.withMutations(map => {
    map.set('virtual_link', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_LATITUDE_CHANGE:
  return state.withMutations(map => {
    map.set('latitude', action.value);
    map.set('isChanged', true);
  });
  case EVENT_EDITOR_LONGITUDE_CHANGE:
  return state.withMutations(map => {
    map.set('longitude', action.value);
    map.set('isChanged', true);
  });
  case EVENT_UPLOAD_REQUEST:
    return state.set('is_uploading', true).update('pending_media_attachments', n => n + 1);
  case EVENT_UPLOAD_SUCCESS:
    return appendMedia(state, fromJS(action.media), action.file);
  case EVENT_UPLOAD_FAIL:
    return state.set('is_uploading', false).set('is_processing', false).update('pending_media_attachments', n => n - 1);
  case EVENT_UPLOAD_PROGRESS:
    return state.set('progress', Math.round((action.loaded / action.total) * 100));
  case EVENT_CREATE_REQUEST:
  case EVENT_UPDATE_REQUEST:
    return state.withMutations(map => {
      map.set('isSubmitting', true);
      map.set('isChanged', false);
    });
  case EVENT_CREATE_FAIL:
  case EVENT_UPDATE_FAIL:
    return state.set('isSubmitting', false);
  case EVENT_CREATE_SUCCESS:
  case EVENT_UPDATE_SUCCESS:
    return state.withMutations(map => {
      map.set('isSubmitting', false);
      map.set('eventId', action.event.id);
    });
  case EVENT_ACCOUNTS_FETCH_REQUEST:
    return state.setIn(['accounts', 'isLoading'], true);
  case EVENT_ACCOUNTS_FETCH_FAIL:
    return state.setIn(['accounts', 'isLoading'], false);
  case EVENT_ACCOUNTS_FETCH_SUCCESS:
    return state.update('accounts', accounts => accounts.withMutations(map => {
      map.set('isLoading', false);
      map.set('loaded', true);
      map.set('items', ImmutableList(action.accounts.map(item => item.id)));
    }));
  case EVENT_EDITOR_SUGGESTIONS_CHANGE:
    return state.setIn(['suggestions', 'value'], action.value);
  case EVENT_EDITOR_SUGGESTIONS_READY:
    return state.setIn(['suggestions', 'items'], ImmutableList(action.accounts.map(item => item.id)));
  case EVENT_EDITOR_SUGGESTIONS_CLEAR:
    return state.update('suggestions', suggestions => suggestions.withMutations(map => {
      map.set('items', ImmutableList());
      map.set('value', '');
    }));
  case EVENT_EDITOR_ADD_SUCCESS:
    return state.updateIn(['accounts', 'items'], event => event.unshift(action.accountId));
  case EVENT_EDITOR_REMOVE_SUCCESS:
    return state.updateIn(['accounts', 'items'], event => event.filterNot(item => item === action.accountId));
  default:
    return state;
  }
}
